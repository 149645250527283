body {
    --AC-primary: #087cfa;
    --AC-secondary: #00beee;
    --AC-ternary: #2bc382;

    --CL-primary: #2bc382;
    --CL-secondary: #009ae5;
    --CL-ternary: #ed358c;

    --CWM-primary: #6b57ff;
    --CWM-secondary: #3bea62;

    --DG-primary: #2bc382;
    --DG-secondary: #6b57ff;
    --DG-ternary: #ff318c;

    --DS-primary: #2bc382;
    --DS-secondary: #fcf84a;
    --DS-ternary: #087cfa;

    --GO-primary: #087cfa;
    --GO-secondary: #b74af7;
    --GO-ternary: #2BC382;

    --IJ-primary: #fe315d;
    --IJ-secondary: #087cfa;
    --IJ-ternary: #fc801d;

    --IJ-Ult-primary: #087cfa;
    --IJ-Ult-secondary: #fe2857;
    --IJ-Ult-ternary: #fc801d;

    --MPS-primary: #087cfa;
    --MPS-secondary: #2bc382;
    --MPS-ternary: #fcf84a;

    --PS-primary: #b74af7;
    --PS-secondary: #6b57ff;
    --PS-ternary: #ff318c;

    --PC-primary: #00beee;
    --PC-secondary: #2bc382;
    --PC-ternary: #fcf84a;

    --PC-Pro-primary: #2bc382;
    --PC-Pro-secondary: #fcf84a;
    --PC-Pro-ternary: #00beee;

    --RS-Cpp-primary: #fdb60d;
    --RS-Cpp-secondary: #ff45ed;
    --RS-Cpp-ternary: #dd1265;

    --RS-primary: #dd1265;
    --RS-secondary: #ff45ed;
    --RS-ternary: #fdb60d;

    --RD-primary: #c90f5e;
    --RD-secondary: #087cfa;
    --RD-ternary: #fdb60d;

    --RM-primary: #fe2857;
    --RM-secondary: #fc801d;
    --RM-ternary: #6b57ff;

    --TC-primary: #00beee;
    --TC-secondary: #6b57ff;
    --TC-ternary: #2BC382;

    --WS-primary: #00beee;
    --WS-secondary: #087cfa;
    --WS-ternary: #fcf84a;

    --YT-primary: #00beee;
    --YT-secondary: #6b57ff;
    --YT-ternary: #ff318c;

    --DC-primary: #fc801d;
    --DC-secondary: #7866ff;

    --DM-primary: #fdb60d;
    --DM-secondary: #6b57ff;

    --DP-primary: #00caff;
    --DP-secondary: #6b57ff;
    --DP-ternary: #e343e6;

    --DT-primary: #fc1681;
    --DT-secondary: #6b57ff;
    --DT-ternary: #ff45ed;

    --DL-primary: #21d789;
    --DL-secondary: #fcf84a;
    --DL-ternary: #087cfa;

    --HB-primary: #07c3f2;
    --HB-secondary: #6b57ff;
    --HB-ternary: #fcf84a;

    --UP-primary: #07c3f2;
    --UP-secondary: #6b57ff;
    --UP-ternary: #fc801d;

    --QD-primary: #6b57ff;
    --QD-secondary: #ff318c;
    --QD-ternary: #fc801d;

    --EDU-primary: #af1df5;
    --EDU-secondary: #2bc382;
    --EDU-ternary: #6b57ff;
}

.mod--ac {
  --primary: var(--AC-primary);
  --secondary: var(--AC-secondary);
  --ternary: var(--AC-ternary);
}

.mod--cl {
  --primary: var(--CL-primary);
  --secondary: var(--CL-secondary);
  --ternary: var(--CL-ternary);
}

.mod--cwm {
  --primary: var(--CWM-primary);
  --secondary: var(--CWM-secondary);
  --ternary: var(--CWM-ternary);
}

.mod--dc {
  --primary: var(--DC-primary);
  --secondary: var(--DC-secondary);
  --ternary: var(--DC-ternary);
}

.mod--dg {
  --primary: var(--DG-primary);
  --secondary: var(--DG-secondary);
  --ternary: var(--DG-ternary);
}

.mod--dl {
  --primary: var(--DL-primary);
  --secondary: var(--DL-secondary);
  --ternary: var(--DL-ternary);
}

.mod--dm {
  --primary: var(--DM-primary);
  --secondary: var(--DM-secondary);
  --ternary: var(--DM-ternary);
}

.mod--dp {
  --primary: var(--DP-primary);
  --secondary: var(--DP-secondary);
  --ternary: var(--DP-ternary);
}

.mod--ds {
  --primary: var(--DS-primary);
  --secondary: var(--DS-secondary);
  --ternary: var(--DS-ternary);
}

.mod--dt {
  --primary: var(--DT-primary);
  --secondary: var(--DT-secondary);
  --ternary: var(--DT-ternary);
}

.mod--edu {
  --primary: var(--EDU-primary);
  --secondary: var(--EDU-secondary);
  --ternary: var(--EDU-ternary);
}

.mod--fl {
  --primary: var(--FL-primary);
  --secondary: var(--FL-secondary);
  --ternary: var(--FL-ternary);
}

.mod--gl {
  --primary: var(--GO-primary);
  --secondary: var(--GO-secondary);
  --ternary: var(--GO-ternary);
}

.mod--hub {
  --primary: var(--HB-primary);
  --secondary: var(--HB-secondary);
  --ternary: var(--HB-ternary);
}

.mod--idea {
  --primary: var(--IJ-primary);
  --secondary: var(--IJ-secondary);
  --ternary: var(--IJ-ternary);
}

.mod--ij-ult {
  --primary: var(--IJ-Ult-primary);
  --secondary: var(--IJ-Ult-secondary);
  --ternary: var(--IJ-Ult-ternary);
}

.mod--db {
  --primary: var(--JB-primary);
  --secondary: var(--JB-secondary);
  --ternary: var(--JB-ternary);
}

.mod--kotlin {
  --primary: var(--Kotlin-primary);
  --secondary: var(--Kotlin-secondary);
  --ternary: var(--Kotlin-ternary);
}

.mod--mps {
  --primary: var(--MPS-primary);
  --secondary: var(--MPS-secondary);
  --ternary: var(--MPS-ternary);
}

.mod--pc {
  --primary: var(--PC-primary);
  --secondary: var(--PC-secondary);
  --ternary: var(--PC-ternary);
}

.mod--pc-edu {
  --primary: var(--PC-Edu-primary);
  --secondary: var(--PC-Edu-secondary);
  --ternary: var(--PC-Edu-ternary);
}

.mod--pc-pro {
  --primary: var(--PC-Pro-primary);
  --secondary: var(--PC-Pro-secondary);
  --ternary: var(--PC-Pro-ternary);
}

.mod--ps {
  --primary: var(--PS-primary);
  --secondary: var(--PS-secondary);
  --ternary: var(--PS-ternary);
}

.mod--q {
  --primary: var(--QD-primary);
  --secondary: var(--QD-secondary);
  --ternary: var(--QD-ternary);
}

.mod--r {
  --primary: var(--RD-primary);
  --secondary: var(--RD-secondary);
  --ternary: var(--RD-ternary);
}

.mod--rd {
  --primary: var(--RD-primary);
  --secondary: var(--RD-secondary);
  --ternary: var(--RD-ternary);
}

.mod--rm {
  --primary: var(--RM-primary);
  --secondary: var(--RM-secondary);
  --ternary: var(--RM-ternary);
}

.mod--rs {
  --primary: var(--RS-primary);
  --secondary: var(--RS-secondary);
  --ternary: var(--RS-ternary);
}

.mod--rscpp {
  --primary: var(--RS-Cpp-primary);
  --secondary: var(--RS-Cpp-secondary);
  --ternary: var(--RS-Cpp-ternary);
}

.mod--tb {
  --primary: var(--TB-primary);
  --secondary: var(--TB-secondary);
  --ternary: var(--TB-ternary);
}

.mod--tc {
  --primary: var(--TC-primary);
  --secondary: var(--TC-secondary);
  --ternary: var(--TC-ternary);
}

.mod--us {
  --primary: var(--UP-primary);
  --secondary: var(--UP-secondary);
  --ternary: var(--UP-ternary);
}

.mod--ws {
  --primary: var(--WS-primary);
  --secondary: var(--WS-secondary);
  --ternary: var(--WS-ternary);
}

.mod--yt {
  --primary: var(--YT-primary);
  --secondary: var(--YT-secondary);
  --ternary: var(--YT-ternary);
}


.mod--marketplace, .mod--mplace {
  --primary: #fc801d;
  --secondary: #087cfa;
}


/* overrides? */



.mod--dc {
  --primary: #fc801d;
  --secondary: #7866ff;
}

.mod--yt {
  --primary: #6b57ff;
  --secondary: #07c3f2;
}

.mod--fl {
  --primary: #087cfa;
  --secondary: #af1df5;
  --ternary: #07c3f2;
}