body {
    --xs: 24px;
    --s: 32px;
    --m: 48px;
    --l: 56px;
    --xl: 64px;
    --xxl: 72px;
    --xxxl: 96px;
    --zoom: 0.4;

    --x_15: calc(var(--xs) * 1.5);
    --x_2: calc(var(--xs) * 2);
    --x_25: calc(var(--xs) * 2.5);
    --x_3: calc(var(--xs) * 3);
    --x_4: calc(var(--xs) * 4);
}

.text--kind--hero {
    font-weight: 530;
    font-size: 120px;
    line-height: 120px;
}

.text--kind--h1 {
    font-weight: 530;
    font-size: var(--xxxl);
    line-height: var(--xxxl);
}

.text--kind--h2 {
    font-weight: 530;
    font-size: 80px;
    line-height: var(--xxxl);
}

.text--kind--h3 {
    font-weight: 530;
    font-size: 75px;
    line-height: 88px;
}

.text--kind--h4 {
    font-weight: 530;
    font-size: 60px;
    line-height: var(--xxl);
}

.text--kind--text1 {
    font-weight: 530;
    font-size: 60px;
    line-height: var(--xxl);
}

.text--kind--text2 {
    font-weight: 459;
    font-size: 36px;
    line-height: var(--m);
}

.text--kind--text3 {
    font-weight: 400;
    font-size: var(--s);
    line-height: 36px;
}

.template {

    zoom: var(--zoom);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1;

}

.text {
    color: white;
    font-family: 'JetBrains Sans';
    font-style: normal;
}


.text:focus {
    outline: none;
}

.logo {
    width: var(--xxxl);
    height: var(--xxxl);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
}

.image--form {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
}


.image--drop-zone {

    border: 1px dashed rgba(200,200,200,0.5);
    border-radius: 32px;
    width: 1182px;
    height: 1296px;
    /* zoom: var(--zoom); */
    zoom: 0.75;
    position: absolute;
    right: 5%;
    bottom: 5%;
    text-align: center;

    &:before {
        content: "Click or drop image here";
        color: rgba(220,220,220,0.8);
        font-size: var(--xxl);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
    }

}

.image--drop-zone.invisible {
    display: none;
}

.logo-with-text-wrapper {

    display: flex;

    .logo-text {
        display: inline-block;
        margin: 0px auto;
        margin-left: var(--s);
        font-size: var(--xxl);
        font-weight: 530;
        font-family: "JetBrains Sans", sans-serif;
        color: white;
    }

}

.template-link {
    text-decoration: none;
    margin: 0 5px;

    &:hover {
        background: lightgray;
    }
}

.template-and-info {
    position: relative;

    margin-bottom: 100px; /* FIXME */
}

.template-size {
    margin-left: 2px;
    /*font-weight: normal;*/
    /*font-size: 14px;*/

    &:before {
        content: "(";
    }

    &:after {
        content: ")";
    }
}

.template-show-info {

    position: absolute;
    top: 1em;
    right: 40px;

    .template-show-info-button {
        position: absolute;
        margin: 20px 10px;
        border: 1px dashed gray;
        padding: 8px 10px;
        border-radius: 40px;
        cursor: pointer;
        background-color: lightgray;
        opacity: 0.05;
    }

    .info-block {
        position: absolute;
        display: none;
        right: 20px;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid lightgray;
    }

    &:hover {

        .info-block {
            display: inline-block;
        }
    }
}

.template iframe {
    border-width: 0;
    position: absolute;
    z-index: -1000;

    #artwork {
        zoom : var(--zoom);
    }
}

/* .mod--ac {
    --product-name: "AppCode";
}

.mod--cl {
    --product-name: "CLion";
}

.mod--cwm {
    --product-name: "CodeWithMe";
} */
