.modifier-vars {

    display: inline-flex;
    position: relative;
    margin: 0 5px;
    padding: 0;
    list-style-type: none;
    border: 1px solid gray;
    border-radius: 6px;


    .var-item {

        a {
            padding: 3px 11px;
            color: darkgray;
            text-decoration: none;
        }

        &.active-var {

            padding: 0;
            border: 2px solid gray;
            border-radius: 6px;

            a {
                color: black;
            }
        }

    }

}

a.collection-link {
    position: relative;
    font-size: 21px;
    top: -2px;
    text-decoration: none;
    border-radius: 3px;

    &:hover {
        background-color: lightgray;
    }
}

.collection-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.collection-main {
    margin-top: -66px;
}

.zip-title {
    font-size: 0;
}