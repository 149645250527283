body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav h4 {
  margin-left: 20px;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 64px;
  margin-top: 40px;

  @media (max-width: 1400px) {
    margin-right: 32px;
  }
}

.page {
  flex: 1;
  padding: 64px;
  padding-top: 0;
  z-index: 1;

  @media (max-width: 1400px) {
    padding: 32px;
    padding-top: 0;
  }
}

#constructor {
  background-color: rgba(30, 30, 30, 1.0);
  height: 100vh;

  .logo--placeholder {
    width: 96px;
    height: 96px;
    border: 1px dashed white;
  }
}