.template {

    &.mod--roadmap {

        .text--title {
            max-width: 50%;
        }

        .image {
            position: absolute;
            max-width: 1500px; /* FIXME */
            height: calc(100% - var(--xs));
            zoom: 1.4;
            right: 0;
            bottom: 0;
        }
    }

    &.mod--atw {

        .text--title {
            max-width: 50%;
        }

    }

    &.mod--fomo_alt {
        padding: 0;
    }

}

/* ======= Blog / Social ======= */

.group--blog_image,
.group--social_share {

    padding: 112px 144px;

    .text--name {
        color: var(--primary);
    }

    /* FIXME: use --2x class or something */

    .text--kind--h1 {
        font-size: calc(var(--xxxl) * 2);
        line-height: calc(var(--xxxl) * 2);
    }

    .text--kind--h2 {
        font-size: calc(80px * 2);
        line-height: calc(var(--xxxl) * 2);
    }

    .text--kind--h4 {
        font-size: calc(60px * 2);
        line-height: calc(var(--xxl) * 2);
    }

    &.mod--guest-post {
        .text--name {
            color: white;
        }
    }

    &.mod--marketplace {

        &.mod--interviews {
            .text--name {
                color: black;
            }
        }

        &.mod--busy-dev {
            .text--name {
                color: white;
            }

            .text--subtitle {
                color: black;
            }
        }
    }

}

.group--blog_image, .group--social_share {

    .logos {
        position: absolute;
        left: calc(var(--xxl) * 2);
        bottom: calc(var(--xxl) * 2);

        .logo {
            margin-right: calc(var(--m) * 2);
            width: calc(var(--xxxl) * 2);
            height: calc(var(--xxxl) * 2);

            &.logo--jetbrains-academy-text {
                width: auto;
                height: calc(var(--xxxl) * 3.5);
                transform: translate(calc(var(--xxxl) * -1), calc(var(--xxxl) * 0.75));
            }
        }
    }

}

.template--instagram_l, .template--instagram_r {

    padding: var(--xxxl);

    .text--date {
        color: var(--primary);
    }

    .logo {
        width: 144px;
        height: 144px;
    }

    .text--primary {
        margin-top: var(--xxxl);
    }

    .image--form {
        width: 1080px;
        height: 1080px;
        top: inherit;
        bottom: 0;
    }

    .text--name {
        color: var(--primary);
    }
}

.template--blog_image, .template--social_share {

    &.mod--dm.mod--howtos {

        .text--title {
            color: black;
        }

    }

    &.mod--jb {

        .text--name {
            color: #878787;
        }

        .background {
            background-color: #28272a;
        }

    }

    &.mod--dl {

        &.mod--blue {

            .text--title {
                color: white;
            }

        }

        &.mod--green, &.mod--yellow {

            .text--title {
                color: black;
            }

        }

    }

    &.mod--mps.mod--events {
        .text--title {
            color: black;
        }
    }

    &.mod--pc.mod--features {
        .text--title {
            color: black;
        }
    }

    &.mod--ws {

        &.mod--interviews {

            .text--name {
                color: var(--ternary);
            }

            &.mod--var2 {
                .text--name, .text--subtitle {
                    color: black;
                }
            }
        }

        &.mod--jsr {

            .text--name {
                color: var(--ternary);
            }

        }

    }

    &.mod--edu {

        .text--name {
            color: white;
        }

        .text--subtitle {
            color: var(--secondary);
        }

    }

}

/* ======= Youtube & Webinars ======= */

.template--w_yt_cover {

    padding: var(--xxl);

    .text--title {
        margin-top: var(--l);
    }

    .text--name {
        margin-top: auto;
    }

}

.template--w_yt_thumb {

    padding: var(--m);

    .text--title {
        margin-top: var(--s);
    }

    .text--name {
        margin-top: auto;
    }

}

.template--w_yt_community {

    padding: var(--xxxl);

    .text--title {
        margin-top: var(--s);
    }

    .image--form {
        width: 776px;
        height: 856px;
        top: inherit;
        right: -15%;
        bottom: -18%;
    }

    .text--name {
        margin-top: auto;
    }

}

.template--w_twitter, .template--w_facebook, .template--w_linkedin {

    padding: var(--xxl);

    .text--title {
        margin-top: var(--s);
    }

    .text--name {
        margin-top: auto;
    }

}

.template--yv_yt_cover, .template--yv_yt_thumb, .template--yv_yt_community {

    padding: var(--xxl);

    .text--title {
        margin-top: var(--l);
    }

    .text--name {
        margin-top: auto;
    }

}


/* Annotated */

.collection--annotated .group--blog_image .text--date {
    color: #ffbd00;
}

/* Ads */

.group--ad_square_l1, .group--ad_square_l2, .group--ad_square_m1, .group--ad_square_m2,
.group--ad_vert_wide, .group--ad_vert_normal, .group--ad_vert_narrow,
.group--ad_horz_tall, .group--ad_horz_middle, .group--ad_horz_short,
.group--ad_horz_small1, .group--ad_horz_small2, .group--ad_horz_small3 {
    position: relative;
    padding: var(--s);

    .text--kind--product-name {
        letter-spacing: -0.02em;
    }

    .logo {
        position: absolute;
    }

    .button {
        background-color: #fff;
        border-radius: var(--m);
        text-decoration: none;
        padding: var(--s) var(--m);
        font-size: var(--s);
        color: black;
        font-weight: 500;
        line-height: 28px;
        position: absolute;
    }
}

.group--ad_square_l1, .group--ad_square_l2, .group--ad_square_m1, .group--ad_square_m2 {

    .text--kind--product-name {
        font-style: normal;
        font-weight: 700;
        font-size: 220px;
        line-height: 200px;
        max-height: 200px;
        overflow: hidden;
    }

    .text--kind--tagline {
        font-weight: 600;
        font-size: var(--xxl);
        line-height: 110%;
        overflow: hidden;
    }

    .logo {
        bottom: var(--s);
        right: var(--s);
    }

    .button {
        bottom: var(--s);
        left: var(--s);
    }

}

.group--ad_square_m1  {
    .text--kind--product-name {
        font-size: 200px;
        line-height: 180px;
    }

    .text--kind--tagline {
        font-size: var(--xl);
    }
}

.group--ad_square_m2 {
    .text--kind--product-name {
        font-size: var(--xxl);
        line-height: 120px;
    }

    .text--kind--tagline {
        font-size: var(--l);
    }
}

.group--ad_vert_wide, .group--ad_vert_normal, .group--ad_vert_narrow {
    .text--kind--product-name {
        font-weight: 700;
        font-size: 76px;
        line-height: 89px;
    }

    .text--kind--tagline {
        font-size: var(--m);
        font-weight: 600;
        line-height: 110%;
    }

    .logo {
        top: var(--s);
    }

    .button {
        bottom: var(--s);
    }
}

.group--ad_horz_tall, .group--ad_horz_middle, .group--ad_horz_short,
.group--ad_horz_small1, .group--ad_horz_small2, .group--ad_horz_small3 {
    .text--kind--product-name {
        font-weight: 700;
        font-size: 245px;
        line-height: 287px;
    }

    .text--kind--tagline {
        font-weight: 600;
        font-size: 89px;
        line-height: 110%;
    }

    .button {
        bottom: var(--s);
        right: var(--s);
    }

    .logo {
        top: var(--s);
        right: var(--s);
    }
}

.group--icon_32, .group--icon_48, .group--icon_64, .group--icon_96, .group--icon_128, .group--icon_256, .group--icon_512 {

    position: relative;
    font-weight: bold;
    padding: 0.2em;

    .text--kind--icon-prod-code {
        line-height: 80%;
        letter-spacing: -.05em;
        text-transform: uppercase;

    }

    .text--kind--cursor {
        position: absolute;
        bottom: 0.2em;
    }
}

.group--icon_32 {
    font-size: 14px;
}
.group--icon_48 {
    font-size: 21px;
}
.group--icon_64 {
    font-size: 28px;
}
.group--icon_96 {
    font-size: 41.7px;
}
.group--icon_128 {
    font-size: 55.6px;
}
.group--icon_256 {
    font-size: 111.2px;
}
.group--icon_512 {
    font-size: 222.5px;
}